/*************************************************************** Root *********************** */

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: "Montserrat", "Helvetica Neue", Arial, Helvetica, sans-serif;
  font-family: "Open Sans", serif;
  font-optical-sizing: auto;
  font-style: normal;
}

footer {
  margin-top: auto;
  margin-bottom: -30px;
}

/*************************************************************** NavBar *********************** */

.navbar-custom {
  background-color: #8baac2;
}

.navbar-initials {
  min-width: 100px;
  min-height: 100px;
  display: inline-block;
  padding: 0px !important;
  margin: 0px !important;
}

.navbar-icon {
  outline-width: 0;
}

.nav-link {
  color: #ffffff;
  font-size: 23px;
}

.nav-link:hover {
  color: #ffd84a;
}

.navbar-toggler-icon {
  filter: opacity(0.6);
}

/*************************************************************** Main Containers *********************** */

.main-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #8baac2;
}

.secondary-container {
  flex-direction: row;
  padding: 10px 20px;
}

.heading-italic {
  color: #425c77;
  font-style: oblique;
}

.heading-bold {
  color: #425c77;
  font-weight: 600;
}

/*************************************************************** Contact Section *********************** */

.contact-btn {
  cursor: pointer;
  color: #91b2cd;
  margin: 0px 3px 0px 3px;
}

.contact-img {
  fill: #173c5a;
}

.contact-img:hover {
  fill: #ffd84a;
  transition: fill 0.1s ease-in-out;
}

/*************************************************************** Desk Styles *********************** */

.desk-container {
  display: flex;
}

.desk-component {
  width: 100%;
  transform: translate(-40px, 120px) scale(1.45);
}

.subtitle {
  font-size: 140px;
  fill: #1f3b5a;
}

.subtitle-2 {
  fill: #1f3b5a;
  font-size: 30px;
  font-family: "Arial Narrow", Arial, sans-serif;
}

.teapot:hover {
  filter: brightness(0.6);
}

/*************************************************************** About Page *********************** */

.about-title {
  transform: translateX(2600px) translateY(3600px) scale(5.3) rotate(-8deg);
  transition: all 0.3s ease;
}

.about {
  display: flex;
  flex-direction: column;
  align-content: center;
  padding-bottom: 30px;
  color: #173c5a;
}

.about-col-1 {
  padding: 0px 30px 20px 30px;
}

.about-col-2 {
  padding: 0px 30px 0px 30px;
}

.about-section-1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about-intro {
  margin-bottom: 20px;
  margin-top: 20px;
  width: 90%;
  text-align: center;
}

.headshot {
  width: 200px;
  border-radius: 3px;
  /*box-shadow: 0px 0px 8px #6d8698;*/
}

.education-section {
  font-size: 13px;
  background-color: #a4c6df;
  height: fit-content;
  padding: 20px 20px 25px 20px;
  color: #173c5a;
  border-radius: 5px;
}

.year-title {
  font-weight: bold;
  padding-right: 10px;
  color: #5a81aa;
}

.inline-link {
  text-decoration: underline;
  color: inherit;
}

.tech-title {
  text-align: center;
  color: #173c5a;
}

.skills-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.tech-skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 60%;
}

.tech-skills {
  width: fit-content;
  border-width: 1px;
  color: #173c5a;
  background-color: #b5dbf5;
  border-radius: 10px;
  padding: 0px 5px 0px 5px;
  margin: 3px 6px 3px 0px;
}

.adobe-icon-container {
  text-align: center;
}

.adobe-icon {
  width: 35px;
  margin: 10px 3px 3px 3px;
}

/*************************************************************** Portfolio Page *********************** */

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid #fff;
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.portfolio-menu {
  flex-direction: column;
  background-color: #a4c6df;
  border-radius: 20px;
  padding: 8px 8px 8px 8px;
  gap: 3px;
  box-shadow: 0px 0px 5px #7b98ac;
}

.portfolio-nav-link {
  text-decoration: none;
  color: #5a81aa;
}

.portfolio-btn {
  padding: 3px 10px 3px 10px;
  margin: 0px 0 0px 0;
}

.portfolio-btn:hover {
  background-color: #b5dbf5;
}

.portfolio-btn-selected {
  background-color: #b5dbf5;
  box-shadow: 0px 0px 4px rgb(104, 151, 167);
}

/*************************************************************** Web Dev Portfolio *********************** */

.web-dev-card {
  background-color: rgba(164, 198, 223);
  box-shadow: 0px 0px 5px #6d8698;
  padding-bottom: 10px;
  height: 100%;
}

.web-dev-card-img {
  height: 200px;
}

.web-dev-subtitle {
  font-style: oblique;
  color: #425c77;
  font-size: medium;
}

.link-container {
  display: flex;
  flex-direction: row;
  justify-content: start;
  margin-bottom: 7px;
}

.link-btns {
  display: flex;
  justify-content: center;
  color: #425c77;
  margin: 0px 5px 0px 0px;
  padding: 3px 5px 3px 5px;
  background-color: #b5dbf5;
  border-radius: 5px;
  text-decoration: none;
  width: 75px;
}

.link-btns:hover {
  filter: brightness(0.8);
}

/************************************************** Pottery Portfolio  *********************/
.pot-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 10px;
  column-gap: 10px;
}

.pot-item {
  break-inside: avoid;
  flex: 1 1 calc(25% - 20px);
  max-width: 200px;
  min-width: 150px;
  max-height: 350px;
  height: 250px;
  align-self: center;
  position: relative;
}

.pot-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.pot-image:hover {
  filter: brightness(0.6);
}

/*************************************************************** Design Portfolio *********************** */

.design-nav {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #8baac2;
  padding: 5px;
  color: #425c77;
  overflow: visible;
}

.design-nav-item {
  color: #425c77;
  margin: 10px;
}

.design-container {
  column-count: 2;
  column-gap: 20px;
}

.design-item {
  break-inside: avoid;
  margin-bottom: 20px;
  position: relative;
}

.design-img {
  width: 100%;
  object-fit: cover;
  border-radius: 4px;
}

.video-container {
  display: flex;
  flex-direction: column;
}

.caption {
  color: #425c77;
  font-size: medium;
  font-weight: 400;
}

/*************************************************************** MEDIA QUERIES *********************** */

/*************************************************************** Adjust design portfolio image grid *********************** */

@media (min-width: 600px) {
  .design-container {
    column-count: 3;
    column-gap: 20px;
  }
}

/*************************************************************** Adjust pottery portfolio image grid *********************** */

@media (min-width: 550px) {
  .pot-container {
    column-count: 3;
  }
}

@media (min-width: 750px) {
  .pot-container {
    column-count: 4;
  }
}

/* For a very narrow screen */
@media (min-width: 305px) {
  .portfolio-menu {
    flex-direction: row;
  }
}

/* Contact container */
@media (min-width: 300px) {
  .contact-container {
    width: 300px;
  }
}

/* This is for the home page desk component
   It has a different break point than normal due to the illustraion size */

@media (min-width: 576px) {
  .desk-component {
    width: 100%;
    transform: translate(-50px, 70px) scale(1.2);
  }

  .desk {
    width: 100%;
  }

  .contact-btn {
    cursor: pointer;
    color: #91b2cd;
    margin: 0px 5px 0px 5px;
  }
}

@media (min-width: 950px) {
  .desk-component {
    width: 100%;
    transform: translate(0px, 0px) scale(1);
  }

  .desk {
    width: 100%;
  }

  .about {
    z-index: 2;
    display: flex;
    flex-direction: row;
    margin: 50px 0px 20px 0px;
    padding: 0px 10px 0px 10px;
  }

  .about-title {
    transform: translateX(900px) translateY(3300px) scale(4) rotate(-8deg);
    transition: all 0.3s ease;
  }

  .about-col-1 {
    display: flex;
    flex-direction: column;
  }

  .about-col-2 {
    display: flex;
    flex-direction: column;
  }

  .about-section-1 {
    display: flex;
    flex-direction: row;
    height: fit-content;
    width: 100%;
  }

  .about-intro {
    text-align: left;
    margin: 0px;
    align-self: center;
    width: 100%;
    margin-left: 20px;
  }

  .headshot {
    width: 150px;
    border-radius: 3px;
    align-self: flex-start;
  }

  .education-section {
    display: flex;
    flex-direction: column;
  }

  .skills-section {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 100%;
    align-items: flex-start;
  }

  .tech-title {
    text-align: left;
  }

  .tech-skills-container {
    justify-content: left;
    width: 100%;
  }

  .adobe-icon-container {
    justify-self: left;
  }
}
